$font-family-default: 'Helvetica', sans-serif;

@function calc-responsive($value1, $width1, $value2, $width2) {
  $x1: $width1;
  $x2: $width2;
  $y1: $value1;
  $y2: $value2;
  $a: ($y2 - $y1) / ($x2 - $x1);
  $b: ($x2 * $y1 - $x1 * $y2) / ($x2 - $x1);
  @return calc(#{$a * 100vw} + #{$b * 1px});
}

%button {
  font-size: 18px;
  font-weight: 400;
  height: 47px;
  width: 100%;
  display: inline-flex;
  max-width: 167px;
  align-items: center;
  border: none;
  color: #fff;
  background: #355a9e;
}

.kep-login-facebook {
  @extend %button;

  &.small {
    padding: calc-responsive(5, 468, 10, 1920) calc-responsive(10, 468, 15, 1920);
  }

  &.medium {
    padding: calc-responsive(10, 468, 15, 1920) calc-responsive(15, 468, 20, 1920);
  }

  &.metro {
    border-radius: 0;
  }

  .fa {
    margin-right: calc-responsive(5, 468, 10, 1920);
  }

  .fa .facebook-btn {
    background: #4876cb;
  }
}
