.notification{
    padding: 15px;
    .spinner{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .badge {
        display: inline-block;
        padding: .25em .4em;
        height: 20px;
        width: 20px;
        font-size: 70%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 50%;
        color: #fff;
        background-color: #a3d74e;
        position: absolute;
        top: 8px;
        left: 20px;
        padding: 4px 2px;
    }
    .content{
        border-bottom:1px solid #e6ecfc;
        padding: 5px;
        padding-top: 2px;

        color: #48494a;

        .notfy-name{
            color: #ff5a5f;
            font-weight: 600
        }
        .notfy-content{
            color: indianred;
            font-weight: 600
        }

    }
}

.notification1 {
    background-color: #555;
    color: white;
    text-decoration: none;
    padding: 15px 26px;
    position: relative;
    display: inline-block;
    border-radius: 2px;
}

.notification1:hover {
    background: red;
}

.notification1 .badge {
    position: absolute;
    top: 5px;
    margin-left: 10px;
    padding: 4px 4px;
    border-radius: 50%;
    background: red;
    color: white;
}
@media (max-width:768px){
    .notification {
      .badge {
        top: 10px;
      }
    }
  }
