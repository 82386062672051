.header {
  background-color: white;
  -webkit-box-shadow: 2px 2px #eee;
          box-shadow: 2px 2px #eee;
  position: fixed;
  width: 100%;
  top: -20px;
  z-index: 1000;
}

.header .header__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 110px;
}

.header .header__main .header__logolink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header .header__main .header__logolink .header__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 0px 10px;
}

.header .header__main .header__logolink .header__logo > a {
  color: var(--primarys);
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 2.3rem;
  line-height: 33px;
  text-transform: lowercase;
  letter-spacing: 1.5px;
}

.header .header__main .header__logolink .header__logo > a > span {
  color: black;
}

.header .header__main .header__logolink .header__linkgroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 50px;
}

.header .header__main .header__logolink .header__linkgroup .header__linkgroup__link {
  text-decoration: none;
  color: var(--primarys);
  font-weight: 600;
  font-size: 17px;
  display: inline-block;
  margin-left: 40px;
  font-family: 'Poppins', Sans-serif;
  letter-spacing: 0.05em;
}

.header .header__main .header__logolink .header__linkgroup .loginbutton {
  display: none;
}

.header .header__main .header__logolink .header__linkgroup .header__linkgroup__link--active {
  color: #0084ff;
}

.header .header__main .header__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.header .header__main .header__right .notification {
  position: relative;
  border: 0;
  outline: 0;
  background: #fff;
  color: var(--primarys);
  font-size: 16px;
  font-weight: 600;
  margin-right: 15px;
}

.header .header__main .header__right .right__button {
  border: 0px;
  outline: 0px;
  background: white;
  color: var(--primarys);
  font-size: 16px;
  font-weight: 600;
  margin-right: 15px;
  padding-top: 0;
}

.header .header__main .header__right .right__button i {
  margin-right: 10px;
}

.header .header__main .header__menu {
  display: none;
}

.header .header__main .header__desktop__display {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header .header__main .header__mobile__display {
  display: none;
}

.mobile_display_only {
  display: none;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .header {
    max-height: 300px;
    -webkit-box-shadow: 0 0 white;
            box-shadow: 0 0 white;
    position: fixed;
    top: 0;
    border-bottom: 1px solid #eee;
  }
  .header .header__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    height: unset;
  }
  .header .header__main .header__logolink {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 100%;
  }
  .header .header__main .header__logolink .header__logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: 0px !important;
    height: 110px;
  }
  .header .header__main .header__logolink .header__logo .header__menu {
    font-size: 35px !important;
  }
  .header .header__main .header__logolink .header__logo > a {
    color: var(--primarys);
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 40px;
    line-height: 33px;
    text-transform: lowercase;
    letter-spacing: 1px;
    margin-left: 20px;
  }
  .header .header__main .header__logolink .header__logo > a > span {
    color: black;
  }
  .header .header__main .header__logolink .header__linkgroup {
    display: none;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-shadow: none;
            box-shadow: none;
    margin-right: 0px !important;
    margin-left: 0px;
    z-index: 200;
  }
  .header .header__main .header__logolink .header__linkgroup .header__linkgroup__link {
    text-decoration: none;
    color: var(--primarys);
    font-weight: 600;
    font-size: 15px;
    display: inline-block;
    margin-left: 40px;
    font-family: 'Poppins', Sans-serif;
    letter-spacing: 0.05em;
    display: block;
    background-color: white;
    padding: 10px;
    width: 100%;
    margin-left: 0px !important;
    text-align: left;
  }
  .header .header__main .header__menu {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .header .header__main .header__right {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    right: 0px;
    top: 20px;
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
    height: 62px;
    width: 80%;
  }



  .header .header__main .header__right .ant-select-show-search {
    width: 250px !important;
  }
  .header .header__main .header__right .right__button {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .header {
    max-height: 300px;
    -webkit-box-shadow: 0 0 white;
            box-shadow: 0 0 white;
    position: fixed;
    top: 0;
    top: 0;
    border-bottom: 1px solid #eee;
  }
  .header .header__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    height: unset;
  }
  .header .header__main .header__logolink {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 100%;
  }
  .header .header__main .header__logolink .header__logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: 0px !important;
    height: 110px;
  }
  .header .header__main .header__logolink .header__logo > a {
    color: var(--primarys);
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 22px;
    line-height: 33px;
    text-transform: lowercase;
    letter-spacing: 1px;
    margin-left: 13px;
  }
  .header .header__main .header__logolink .header__logo > a > span {
    color: black;
  }
  .header .header__main .header__logolink .header__linkgroup {
    display: none;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-shadow: none;
            box-shadow: none;
    margin-right: 0px !important;
    margin-left: 0px;
    z-index: 200;
  }
  .header .header__main .header__logolink .header__linkgroup .header__linkgroup__link {
    text-decoration: none;
    color: var(--primarys);
    font-weight: 600;
    font-size: 15px;
    display: inline-block;
    margin-left: 40px;
    font-family: 'Poppins', Sans-serif;
    letter-spacing: 0.05em;
    display: block;
    background-color: white;
    padding: 10px;
    width: 100%;
    margin-left: 0px !important;
    text-align: left;
  }
  .header .header__main .header__menu {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .header .header__main .header__right {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    right: 0px;
    top: 10px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 80px;
    width: 150px !important;
  }
  .header .header__main .header__right .location-box-cover {
    -webkit-box-flex: 0.6;
        -ms-flex: 0.6;
            flex: 0.6;
  }
  .header .header__main .header__right .ant-select-show-search {
    width: 125px !important;
  }
  @media only screen and (max-width: 600px) and (min-width: 300px)  {
    .header .header__main .header__right .ant-select-show-search {
      width: 70px !important;
    }
  }
  .header .header__main .header__right .right__button {
    display: none;
  }
}
@media (max-width: 1280px) {
  .header .header__linkgroup__link {
    margin-left: 20px!important;
  }
}

.homepage .homepage__intro__main {
  background-image:url(../assets/images/banner-new.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  padding: 210px 40px;
  font-family: 'work sans', sans-serif;
  margin-top: 100px;
}

.homepage .homepage__intro__main .homepage__intro {
  max-width: 1200px;
}

.homepage .homepage__intro__main .homepage__intro .homepage__intro__top p {
  text-align: left;
  line-height: 0.93;
  letter-spacing: -0.3px;
  color: white;
  font-size: 90px;
  margin-bottom: 60px;
}

.homepage .homepage__intro__main .homepage__intro .homepage__intro__top p span {
  display: block;
  font-family: 'work sans', sans-serif;
  font-weight: bold;
}

.homepage .homepage__intro__main .homepage__intro .homepage__intro__top p label {
  font-weight: 300 !important;
  width: 80%;
  display: block;
}

.homepage .homepage__intro__main .homepage__intro .btn-primary-home {
  color: #fff;
  background: #ff5a5f -webkit-gradient(linear, left top, left bottom, from(#ff7377), to(#ff5a5f)) repeat-x;
  background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
  border-color: #ff5a5f;
  -webkit-box-shadow: none;
          box-shadow: none;
  display: inline-block;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.275rem 0.65rem;
  font-size: 1rem;
  line-height: 1.7;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  margin-left: 15px;
}

.homepage .homepage__intro__main .homepage__intro .homepage__center label {
  opacity: 0.8;
  display: block;
  color: white;
  font-size: 23px;
  font-family: 'work sans', sans-serif;
  word-spacing: 2px;
  margin-bottom: 80px;
  font-weight: 400;
  color: #666 !important;
}

.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 {
  -webkit-box-flex: 0.6;
      -ms-flex: 0.6;
          flex: 0.6;
}

.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 {
  -webkit-box-flex: 0.4;
      -ms-flex: 0.4;
          flex: 0.4;
}

.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 {
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px 15px;
  border-right: 1px solid #eee;
}

.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .what,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .where,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .what,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .where {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
}

.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .ant-select,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .search__what,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .search__where,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .ant-select,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .search__what,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .search__where {
  border: 0px;
  outline: 0px !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .ant-select:focus,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .search__what:focus,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .search__where:focus,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .ant-select:focus,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .search__what:focus,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .search__where:focus {
  border: 0px;
  outline: 0px !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .ant-select-selector,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .ant-select-selector {
  border: 0;
  outline: none;
}

.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .ant-select-show-arrow,
.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .ant-select-show-arrow {
  font-size: 20px !important;
}

.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__searchbutton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__searchbutton button {
  padding: 30px 40px;
  margin-left: 20px;
  border-radius: 5px;
  color: white !important;
  background: #ff5a5f -webkit-gradient(linear, left top, left bottom, from(#ff7377), to(#ff5a5f)) repeat-x;
  background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
  border: 1px solid var(--primarys);
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}

.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__searchbutton button:hover {
  background: var(--primarys);
}

.homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__searchbutton button i {
  margin-right: 15px;
}

.homepage .homepage__intro__main .homepage__intro .homepage__end {
  margin-top: 80px;
  color: black;
}

.homepage .homepage__intro__main .homepage__intro .homepage__end label {
  opacity: 0.8;
  display: block;
  color: white;
  font-size: 20px;
  font-family: 'work sans', sans-serif;
  word-spacing: 2px;
  margin: 20px 0px;
  font-weight: 400;
  color: #666 !important;
}

.homepage .homepage__intro__main .homepage__intro .homepage__end .homepage__browselist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 20px 20px 5px 20px;
}

.homepage .homepage__intro__main .homepage__intro .homepage__end .homepage__browselist .browselist__item {
  width: 120px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px 0px 0px 0px;
  margin-right: 20px;
  background-color: white;
}

.homepage .homepage__intro__main .homepage__intro .homepage__end .homepage__browselist .browselist__item i {
  font-size: 50px;
  margin-bottom: 10px;
}

.homepage .homepage__intro__main .homepage__intro .homepage__end .homepage__browselist .browselist__item p {
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
}

.homepage .homepage__intro__main .homepage__intro .homepage__end .homepage__browselist .browselist__item:hover {
  background-color: var(--primarys);
  color: white;
}

.homepage .homepage__howitworks {
  padding: 30px;
  margin-top: 50px;
}

.homepage .homepage__howitworks .howitworks__title {
  font-size: 40px;
}

.homepage .homepage__howitworks .howitworks__title span {
  font-weight: bold;
}

.homepage .homepage__howitworks .howitworks__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.homepage .homepage__howitworks .howitworks__item .howitworks__itemlist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__left i {
  font-size: 55px;
  color: #87bc15;
}

.homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__left h1 {
  font-size: 50px !important;
  opacity: 0.1;
  text-align: center;
  font-weight: 700;
  color: black;
}

.homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0px 20px;
}

.homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__right h1 {
  font-size: 25px;
}

.homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__right label {
  font-size: 16px !important;
  opacity: 0.4;
  line-height: 1.8;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

.homepage .homepage__hr {
  padding: 20px 30px;
  margin-top: 20px;
}

.homepage .homepage__listing {
  padding: 20px 30px;
  margin-top: 0px;
}

.homepage .homepage__listing .listing__title {
  font-size: 40px;
}

.homepage .homepage__listing .listing__title span {
  font-weight: bold;
}

.homepage .homepage__listing .listing__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 30px;
}

.homepage .homepage__listing .listing__main .listing__heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-flex: 0.5;
      -ms-flex: 0.5;
          flex: 0.5;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.homepage .homepage__listing .listing__main .listing__heading button {
  border: none;
  background-color: white;
  outline: none;
  color: var(--primarys);
  font-size: 16px;
  font-weight: bold;
  opacity: 0.8;
  margin-right: 20px;
}

.homepage .homepage__listing .listing__main .listing__arrow i {
  font-size: 20px;
  cursor: pointer;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .homepage .homepage__intro__main {
    background-image:url(../assets/images/banner-new.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    padding: 210px 20px 100px;
  }
  .homepage .homepage__intro__main .homepage__intro {
    max-width: 1200px;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__intro__top p {
    text-align: left;
    line-height: 0.93;
    letter-spacing: -0.3px;
    color: white;
    font-size: 90px;
    margin-bottom: 30px;
    font-family: 'work sans', sans-serif;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__intro__top p span {
    display: block;
    font-family: 'work sans', sans-serif !important;
    font-weight: bold;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__intro__top p label {
    font-weight: 300 !important;
    display: block;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center {
    font-family: 'work sans', sans-serif;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center label {
    display: block;
    color: #666 !important;
    font-size: 20px;
    font-family: 'work sans', sans-serif;
    word-spacing: 2px;
    margin-bottom: 80px;
    margin-top: 50px;
    font-weight: 400;
    color: #666 !important;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 {
    background-color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 30px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 18px 15px;
    border-right: 1px solid #eee;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .what,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .where,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .what,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .where {
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .ant-select,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .search__what,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .search__where,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .ant-select,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .search__what,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .search__where {
    border: 0px;
    outline: 0px !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .ant-select:focus,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .search__what:focus,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .search__where:focus,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .ant-select:focus,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .search__what:focus,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .search__where:focus {
    border: 0px;
    outline: 0px !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .ant-select-selector,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .ant-select-selector {
    border: 0;
    outline: none;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .ant-select-show-arrow,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .ant-select-show-arrow {
    font-size: 20px !important;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__searchbutton {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__searchbutton button {
    padding: 25px 40px;
    width: 100%;
    margin-left: 0px;
    border-radius: 5px;
    color: white !important;
    background: #ff5a5f -webkit-gradient(linear, left top, left bottom, from(#ff7377), to(#ff5a5f)) repeat-x;
    background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
    border: 1px solid var(--primarys);
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__searchbutton button:hover {
    background: var(--primarys);
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__searchbutton button i {
    margin-right: 15px;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__end {
    color: black;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__end label {
    opacity: 0.8;
    display: block;
    color: white;
    font-size: 20px;
    font-family: 'work sans', sans-serif;
    word-spacing: 2px;
    margin: 80px 0px 20px 0px;
    font-weight: 400;
    color: #666 !important;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__end .homepage__browselist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 20px 10px 5px 10px;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__end .homepage__browselist .browselist__item {
    width: 22%;
    border-radius: 5px;
    margin-bottom: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 20px 0px 30px 0px;
    margin-right: 20px;
    background-color: white;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__end .homepage__browselist .browselist__item i {
    font-size: 50px;
    margin-bottom: 10px;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__end .homepage__browselist .browselist__item p {
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__end .homepage__browselist .browselist__item:hover {
    background-color: var(--primarys);
    color: white;
  }
  .homepage .homepage__howitworks {
    padding: 10px 30px;
    margin-top: 20px;
  }
  .homepage .homepage__howitworks .howitworks__title {
    font-size: 40px;
  }
  .homepage .homepage__howitworks .howitworks__title span {
    font-weight: bold;
  }
  .homepage .homepage__howitworks .howitworks__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .homepage .homepage__howitworks .howitworks__item .howitworks__itemlist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 10px;
  }
  .homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__left i {
    font-size: 40px;
    color: #87bc15;
  }
  .homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__left h1 {
    font-size: 50px;
    opacity: 0.2;
    text-align: center;
    font-weight: bold;
  }
  .homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0px 20px;
  }
  .homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__right h1 {
    font-size: 20px;
  }
  .homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__right label {
    font-size: 16px;
    opacity: 0.5;
    color: black;
    line-height: 1.3;
    font-family: sans-serif;
  }
  .homepage .homepage__hr {
    padding: 10px 10px;
    margin-top: 20px;
  }
  .homepage .homepage__listing {
    padding: 10px 30px;
    margin-top: 0px;
  }
  .homepage .homepage__listing .listing__title {
    font-size: 40px;
  }
  .homepage .homepage__listing .listing__title span {
    font-weight: bold;
  }
  .homepage .homepage__listing .listing__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-bottom: 10px;
  }
  .homepage .homepage__listing .listing__main .listing__heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .homepage .homepage__listing .listing__main .listing__heading button {
    border: none;
    background-color: white;
    outline: none;
    color: var(--primarys);
    font-size: 16px;
    font-weight: bold;
    opacity: 0.8;
  }
  .homepage .homepage__listing .listing__main .listing__arrow {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .homepage .homepage__intro__main {
    background-image:url(../assets/images/banner-new.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    padding: 30px 20px 80px;
  }
  .homepage .homepage__intro__main .homepage__intro {
    max-width: 1200px;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__intro__top p {
    text-align: left;
    line-height: 0.93;
    letter-spacing: -0.3px;
    color: white;
    font-size: 48px;
    margin-bottom: 30px;
    font-family: 'work sans', sans-serif;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__intro__top p span {
    display: block;
    font-weight: bold;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__intro__top p label {
    font-weight: 300 !important;
    display: block;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center label {
    opacity: 0.8;
    display: block;
    color: white;
    font-size: 20px;
    font-family: 'work sans', sans-serif;
    word-spacing: 2px;
    margin-bottom: 50px;
    font-weight: 400;
    color: #666 !important;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 {
    background-color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 15px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 18px 15px;
    border-radius: 5px;
    border-right: 1px solid #eee;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .what,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .where,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .what,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .where {
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .ant-select,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .search__what,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .search__where,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .ant-select,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .search__what,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .search__where {
    border: 0px;
    outline: 0px !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .ant-select:focus,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .search__what:focus,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .search__where:focus,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .ant-select:focus,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .search__what:focus,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .search__where:focus {
    border: 0px;
    outline: 0px !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .ant-select-selector,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .ant-select-selector {
    border: 0;
    outline: none;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search1 .ant-select-show-arrow,
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__search2 .ant-select-show-arrow {
    font-size: 20px !important;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__searchbutton {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__searchbutton button {
    padding: 25px 40px;
    width: 100%;
    margin-left: 0px;
    border-radius: 5px;
    color: white !important;
    background: #ff5a5f -webkit-gradient(linear, left top, left bottom, from(#ff7377), to(#ff5a5f)) repeat-x;
    background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
    border: 1px solid var(--primarys);
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__searchbutton button:hover {
    background: var(--primarys);
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__center .homepage__search .homepage__searchbutton button i {
    margin-right: 15px;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__end {
    color: black;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__end label {
    opacity: 1;
    display: block;
    color: white;
    font-size: 20px;
    font-family: 'work sans', sans-serif;
    word-spacing: 2px;
    margin: 50px 0px 20px 0px;
    font-weight: 400;
    color: #666 !important;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__end .homepage__browselist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 20px 10px 5px 10px;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__end .homepage__browselist .browselist__item {
    width: 40%;
    border-radius: 5px;
    margin-bottom: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 20px 0px 30px 0px;
    margin-right: 20px;
    background-color: white;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__end .homepage__browselist .browselist__item i {
    font-size: 50px;
    margin-bottom: 10px;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__end .homepage__browselist .browselist__item p {
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
  }
  .homepage .homepage__intro__main .homepage__intro .homepage__end .homepage__browselist .browselist__item:hover {
    background-color: var(--primarys);
    color: white;
  }
  .homepage .homepage__howitworks {
    padding: 10px;
    margin-top: 20px;
  }
  .homepage .homepage__howitworks .howitworks__title {
    font-size: 40px;
  }
  .homepage .homepage__howitworks .howitworks__title span {
    font-weight: bold;
  }
  .homepage .homepage__howitworks .howitworks__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .homepage .homepage__howitworks .howitworks__item .howitworks__itemlist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: initial;
  }
  .homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__left i {
    font-size: 40px;
    color: #87bc15;
  }
  .homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__left h1 {
    font-size: 50px;
    opacity: 0.2;
    text-align: center;
    font-weight: bold;
  }
  .homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0px 20px;
  }
  .homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__right h1 {
    font-size: 20px;
  }
  .homepage .homepage__howitworks .howitworks__item .howitworks__itemlist .itemlist__right label {
    font-size: 16px;
    opacity: 0.5;
    color: black;
    line-height: 1.3;
    font-family: sans-serif;
  }
  .homepage .homepage__hr {
    padding: 10px 10px;
    margin-top: 20px;
  }
  .homepage .homepage__listing {
    padding: 10px 10px;
    margin-top: 0px;
  }
  .homepage .homepage__listing .listing__title {
    font-size: 20px;
  }
  .homepage .homepage__listing .listing__title span {
    font-weight: 800;
  }
  .homepage .homepage__listing .listing__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    margin-bottom: 10px;
  }
  .homepage .homepage__listing .listing__main .listing__heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .homepage .homepage__listing .listing__main .listing__heading button {
    border: none;
    background-color: white;
    outline: none;
    color: var(--primarys);
    font-size: 16px;
    font-weight: bold;
    opacity: 0.8;
    margin-right: 0px;
  }
  .homepage .homepage__listing .listing__main .listing__arrow {
    display: none;
  }
}

.footer {
  padding: 40px 25px;
  margin-top: 50px;
  border-top: 1px solid #e2e1e1;
}

.zenDesk {
  position: fixed;
  right: 0;
  bottom: 2px;
  margin-right: 20px;
  z-index: 100000;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  background-color: #ff5a5f;
}

.zenDesk1 {
  position: fixed;
  right: 0;
  bottom: 100px;
  margin-right: 20px;
  z-index: 100000;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  background-color: #ff5a5f;
}
.zenDesk i {
  height: 100%;
  margin-top: 2px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.zenDesk span {
  display: inline-block;
  padding: .25em .4em;
  height: 20px;
  width: 20px;
  font-size: 70%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;
  color: #fff;
  background-color: #a3d74e;
  position: absolute;
  top: 12px;
  right: 20px;
  padding: 4px 2px;
}
.zenDesk1 i {
  height: 100%;
  margin-top: 2px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.zenDesk1 span {
  display: inline-block;
  padding: .25em .4em;
  height: 20px;
  width: 20px;
  font-size: 70%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;
  color: #fff;
  background-color: #a3d74e;
  position: absolute;
  top: 12px;
  right: 20px;
  padding: 4px 2px;
}

.footer .footer__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer .footer__main .footer__left a {
  margin-left: 20px;
  font-size: 20px;
  color: black;
}

.footer .footer__main .footer__center {
  font-size: 18px;
  opacity: 0.8;
}

.footer .footer__main .footer__center a {
  color: #fe5f64;
}

.footer .footer__main .footer__right button {
  border: 0px;
  outline: 0px;
  opacity: 0.8;
  font-size: 18px;
  background-color: white;
}

.footer .footer__main .footer__right button i {
  font-weight: 300;
  font-family: 'font awesome 5 pro';
}

@media screen and (max-width: 800px) {
  .footer {
    margin-top: 100px !important;
    padding: 10px;
  }
  .footer .footer__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .footer .footer__main .footer__left a {
    margin-left: 10px;
    font-size: 20px;
    color: black;
    text-align: center;
    margin-bottom: 12px;
  }
  .footer .footer__main .footer__center {
    margin-bottom: 5px;
    margin-left: 10px;
    font-size: 18px;
    opacity: 0.8;
    text-align: center;
  }
  .footer .footer__main .footer__center a {
    display: inline-block;
    font-size: 16px;
  }
  .footer .footer__main .footer__right button {
    border: 0px;
    outline: 0px;
    opacity: 0.8;
    font-size: 15px;
    background-color: white;
  }
  .footer .footer__main .footer__right button i {
    font-weight: 300;
    font-family: 'font awesome 5 pro';
  }
}

.listingcarousel {
  font-family: 'work sans', sans-serif;
}

.listingcarousel .listingcarousel__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px 5px;
}

.listingcarousel .listingcarousel__main .listing__image {
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  height: 230px;
}

.listingcarousel .listingcarousel__main .listing__image img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.listingcarousel .listingcarousel__main .listing__details .listing__details__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.listingcarousel .listingcarousel__main .listing__details .listing__details__additional .listing__rating {
  padding: 3px 8px;
  color: white;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
}

.listingcarousel .listingcarousel__main .listing__details .listing__details__additional .success {
  background-color: #a3d74e;
}

.listingcarousel .listingcarousel__main .listing__details .listing__details__additional .warning {
  background-color: #ffba00;
}

.listingcarousel .listingcarousel__main .listing__details .listing__details__additional .listing__review {
  margin-left: 5px;
  font-size: 15px;
  opacity: 0.7;
  padding-right: 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  margin-right: 10px;
}

.listingcarousel .listingcarousel__main .listing__details .listing__details__additional .listing__review i {
  color: #09c;
}

.listingcarousel .listingcarousel__main .listing__details .listing__details__additional .fal {
  display: inline-block;
  margin: 7px 0px 0px;
  font-size: 16px;
}

.listingcarousel .listingcarousel__main .listing__details .listing__details__additional .listing__fulladdress {
  margin-left: 10px;
  font-size: 16px;
  opacity: 0.7;
}

.listingcarousel .listingcarousel__main .listing__details .listing__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding-right: 20px;
  margin-top: 20px;
}

.listingcarousel .listingcarousel__main .listing__details .listing__footer .listing__avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.listingcarousel .listingcarousel__main .listing__details .listing__footer .listing__description {
  margin-left: 15px;
  color: #666666;
  line-height: 1.4 !important;
  font-size: 16px;
  word-spacing: -3px;
  letter-spacing: -0.3px;
  text-align: left;
}

@media screen and (min-width: 501px) and (max-width: 800px) {
  .listingcarousel {
    font-family: 'work sans', sans-serif;
  }
  .listingcarousel .listingcarousel__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 20px 5px;
  }
  .listingcarousel .listingcarousel__main .listing__image {
    margin-bottom: 10px;
  }
  .listingcarousel .listingcarousel__main .listing__image img {
    width: 95%;
    height: 345px;
    border: none;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__additional .listing__rating {
    padding: 3px 8px;
    color: white;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__additional .success {
    background-color: #a3d74e;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__additional .warning {
    background-color: #ffba00;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__additional .listing__review {
    margin-left: 5px;
    font-size: 15px;
    opacity: 0.7;
    padding-right: 15px;
    border-right: 1px solid rgba(0, 0, 0, 0.5);
    margin-right: 10px;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__additional .listing__review i {
    color: #09c;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__additional .fal {
    display: inline-block;
    margin: 7px 0px 0px;
    font-size: 16px;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__additional .listing__fulladdress {
    margin-left: 10px;
    font-size: 16px;
    opacity: 0.7;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding-right: 20px;
    margin-top: 20px;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__footer .listing__avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__footer .listing__description {
    margin-left: 15px;
    color: #666666;
    line-height: 1.4 !important;
    font-size: 16px;
    word-spacing: -3px;
    letter-spacing: -0.3px;
    text-align: left;
  }
}

@media screen and (max-width: 500px) {
  .listingcarousel {
    font-family: 'work sans', sans-serif;
  }
  .listingcarousel .listingcarousel__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 30px 20px;
  }
  .listingcarousel .listingcarousel__main .listing__image {
    margin-bottom: 10px;
  }
  .listingcarousel .listingcarousel__main .listing__image img {
    width: 100%;
    height: 180px !important;
    border: none;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__additional .listing__rating {
    padding: 3px 8px;
    color: white;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__additional .success {
    background-color: #a3d74e;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__additional .warning {
    background-color: #ffba00;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__additional .listing__review {
    margin-left: 5px;
    font-size: 15px;
    opacity: 0.7;
    padding-right: 15px;
    border-right: 1px solid rgba(0, 0, 0, 0.5);
    margin-right: 10px;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__additional .listing__review i {
    color: #09c;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__additional .fal {
    display: inline-block;
    margin: 7px 0px 0px;
    font-size: 16px;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__details__additional .listing__fulladdress {
    margin-left: 10px;
    font-size: 16px;
    opacity: 0.7;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding-right: 20px;
    margin-top: 20px;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__footer .listing__avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .listingcarousel .listingcarousel__main .listing__details .listing__footer .listing__description {
    margin-left: 15px;
    color: #666666;
    line-height: 1.4 !important;
    font-size: 16px;
    word-spacing: -3px;
    letter-spacing: -0.3px;
    text-align: left;
  }
}

.style-unordered-list {
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
  z-index: 999;
}

.google-covert {
  border: 1px solid #ccc;
  -webkit-box-shadow: -1px 2px 4px #ccc;
          box-shadow: -1px 2px 4px #ccc;
  width: 100%;
}

@media only screen and (max-width: 600px) and (min-width: 200px)  {
  .location-box-cover {
    background-color: blue;
  }
}

.location-box-cover {
  padding: 10px 10px 10px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #ff5a5f -webkit-gradient(linear, left top, left bottom, from(#ff7377), to(#ff5a5f)) repeat-x;
  background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
  margin-right: 20px;
  border-radius: 5px;
}

.location-box-cover .ant-select-selector {
  background: #ff5a5f -webkit-gradient(linear, left top, left bottom, from(#ff7377), to(#ff5a5f)) repeat-x;
  background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
  border: 0 !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: white !important;
}

.location-box-cover .ant-select-selection-placeholder {
  color: white !important;
  opacity: 1;
}

.location-box-cover .ant-select-arrow {
  color: white;
}

.mapicon {
  color: white;
  font-size: 20px;
}

.location-box {
  height: 40px;
  font-size: 18px;
  /* padding: 10px; */
  margin-left: 15px;
  outline: none;
  border: 0px;
  white-space: nowrap;
  overflow: hidden !important;
  color: white;
  text-overflow: ellipsis;
  width: 180px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: transparent;
}

.style-list {
  cursor: pointer;
  list-style: none;
  border-bottom: 1px solid #aaa;
  height: 40px;
  padding-left: 20px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.style-list:hover {
  background-color: #e7e7e7;
}

.current-loc-ico {
  height: 17px;
  padding-right: 10px;
}

@media screen and (max-width: 800px) {
  .style-unordered-list {
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;
    z-index: 999;
  }
  .google-covert {
    border: 1px solid #ccc;
    -webkit-box-shadow: -1px 2px 4px #ccc;
            box-shadow: -1px 2px 4px #ccc;
    position: absolute;
    width: 100%;
  }
  .location-box-cover {
    padding: 10px 5px 10px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #ff5a5f -webkit-gradient(linear, left top, left bottom, from(#ff7377), to(#ff5a5f)) repeat-x;
    background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
    margin-right: 10px;
    border-radius: 5px;
  }
  .mapicon {
    color: white;
    font-size: 20px;
  }
  .location-box {
    height: 40px;
    font-size: 18px;
    /* padding: 10px; */
    margin-left: 10px;
    outline: none;
    border: 0px;
    white-space: nowrap;
    overflow: hidden !important;
    color: white;
    text-overflow: ellipsis;
    width: 150px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: transparent;
  }
  .location-box::-webkit-input-placeholder {
    color: white;
  }
  .location-box:-ms-input-placeholder {
    color: white;
  }
  .location-box::-ms-input-placeholder {
    color: white;
  }
  .location-box::placeholder {
    color: white;
  }
  .style-list {
    cursor: pointer;
    list-style: none;
    border-bottom: 1px solid #aaa;
    height: 40px;
    padding-left: 20px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .style-list:hover {
    background-color: #e7e7e7;
  }
  .current-loc-ico {
    height: 17px;
    padding-right: 10px;
  }
}

.login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0px auto;
  padding: 40px 20px 10px 20px;
}

.login .login__title {
  text-align: center;
  display: block;
}

.login .login__title label {
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
}

.login .login__title label:nth-child(1) {
  margin-right: 30px;
}

.login .login__form p:nth-child(1) {
  font-size: 20px;
  opacity: 0.8;
  margin-top: 20px;
}

.login .login__form p:nth-child(4) {
  font-size: 20px;
  opacity: 0.8;
  margin-top: 20px;
}

.login .login__form p:nth-child(4) input {
  margin-right: 10px;
}

.login .login__form p:nth-child(6) {
  font-size: 17px;
  opacity: 0.8;
  margin-top: 20px;
}

.login .login__form p:nth-child(6) input {
  margin-right: 10px;
}

.login .login__form input[type='text'],
.login .login__form input[type='password'],
.login .login__form input[type='email'] {
  border: 1px solid #eee;
  padding: 5px 10px;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  width: 100%;
  height: 45px;
  font-size: 18px;
}

.login .login__form input[type='text']::-webkit-input-placeholder,
.login .login__form input[type='password']::-webkit-input-placeholder,
.login .login__form input[type='email']::-webkit-input-placeholder {
  color: black;
  opacity: 0.3;
}

.login .login__form input[type='text']:-ms-input-placeholder,
.login .login__form input[type='password']:-ms-input-placeholder,
.login .login__form input[type='email']:-ms-input-placeholder {
  color: black;
  opacity: 0.3;
}

.login .login__form input[type='text']::-ms-input-placeholder,
.login .login__form input[type='password']::-ms-input-placeholder,
.login .login__form input[type='email']::-ms-input-placeholder {
  color: black;
  opacity: 0.3;
}

.login .login__form input[type='text']::placeholder,
.login .login__form input[type='password']::placeholder,
.login .login__form input[type='email']::placeholder {
  color: black;
  opacity: 0.3;
}

.login .login__form .loginbutton {
  width: 100%;
  border: 0px;
  margin-top: 10px;
  outline: 0px;
  padding: 10px 15px;
  color: white;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  height: 48px;
  background: #ff5a5f -webkit-gradient(linear, left top, left bottom, from(#ff7377), to(#ff5a5f)) repeat-x;
  background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
}

.login .loginwith {
  font-size: 18px;
}

.login .button_group button {
  width: 47%;
  padding: 12px 20px;
  outline: none;
  border: 0px;
  color: white;
  border-radius: 5px;
}

.login .button_group span button {
  margin-right: 10px;
  background-color: #3b5998;
}

.login .button_group .fa-facebook-f {
  margin-right: 5px;
}

.listingmodal {
  border-radius: 5px !important;
}

.listingmodal .ant-modal-title {
  font-size: 20px !important;
}

.listingdetails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px 20px;
}

.listingdetails > div > label {
  font-size: 20px;
  -webkit-box-flex: 0.7;
      -ms-flex: 0.7;
          flex: 0.7;
  font-weight: 300;
  font-size: 19px;
}

.listingdetails > div > label:first-child {
  -webkit-box-flex: 0.3;
      -ms-flex: 0.3;
          flex: 0.3;
          min-width: 150px;
  font-weight: 600;
  display: inline-flex;
}

.listingdetails > div > label:first-child {
  -webkit-box-flex: 0.3;
      -ms-flex: 0.3;
          flex: 0.3;
}

.listingdetails > div .social-icons {
  margin-top: 15px;
  color: #ff5a5f;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.listingdetails > div .social-icons:hover {
  color: black;
}

.listingdetails > div > button {
  background-color: var(--primarys);
  color: white;
  border: none;
  outline: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  margin-top: 15px;
}

.homepage__review {
  padding: 20px 30px;
  font-family: 'work sans', sans-serif;
}

.homepage__review .review__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 40px;
}

.homepage__review .review__main .review__title > span {
  font-size: 40px;
  font-weight: bold;
}

.homepage__review .review__main .review__title > label {
  font-size: 40px;
}

.homepage__review .review__main .review__arrow {
  font-weight: 400;
}

.homepage__review .review__main .review__arrow i {
  font-size: 20px;
  cursor: pointer;
}

.homepage__review .reviewcarousel .reviewcarousel__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px 60px 20px 60px;
}

.homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__image {
  margin-bottom: 0px;
}

.homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__image img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}

.homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__quote {
  text-align: right;
  color: var(--primarys);
  font-size: 20px;
}

.homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__name__org a {
  font-size: 20px;
  color: black;
  margin-right: 10px;
  font-weight: 600 !important;
}

.homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__name__org label {
  font-size: 20px;
  opacity: 0.4;
}

.homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__review {
  margin-top: 10px;
  font-size: 18px;
  opacity: 0.5;
  letter-spacing: -0.01px;
}

@media screen and (min-width: 501px) and (max-width: 800px) {
  .homepage__review {
    padding: 20px 30px;
    font-family: 'work sans', sans-serif;
  }
  .homepage__review .review__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 40px;
  }
  .homepage__review .review__main .review__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .homepage__review .review__main .review__title > span {
    font-size: 40px;
    font-weight: bold;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .homepage__review .review__main .review__title > label {
    font-size: 40px;
    padding: 0px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-left: -10px;
  }
  .homepage__review .review__main .review__arrow {
    display: none;
    font-weight: 400;
  }
  .homepage__review .review__main .review__arrow i {
    font-size: 20px;
    cursor: pointer;
  }
  .homepage__review .reviewcarousel .reviewcarousel__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 20px 60px 20px 60px;
  }
  .homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__image {
    margin-bottom: 0px;
  }
  .homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__image img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }
  .homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__quote {
    text-align: right;
    color: var(--primarys);
    font-size: 20px;
  }
  .homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__name__org a {
    font-size: 20px;
    color: black;
    margin-right: 10px;
    font-weight: 600 !important;
  }
  .homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__name__org label {
    font-size: 20px;
    opacity: 0.4;
  }
  .homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__review {
    margin-top: 10px;
    font-size: 18px;
    opacity: 0.5;
    letter-spacing: -0.01px;
  }
}

@media screen and (max-width: 500px) {
  .homepage__review {
    padding: 10px;
    font-family: 'work sans', sans-serif;
  }
  .homepage__review .review__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 40px;
  }
  .homepage__review .review__main .review__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .homepage__review .review__main .review__title > span {
    font-size: 40px;
    font-weight: bold;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .homepage__review .review__main .review__title > label {
    font-size: 40px;
    padding: 0px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-left: -10px;
  }
  .homepage__review .review__main .review__arrow {
    display: none;
    font-weight: 400;
  }
  .homepage__review .review__main .review__arrow i {
    font-size: 20px;
    cursor: pointer;
  }
  .homepage__review .reviewcarousel .reviewcarousel__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 20px;
  }
  .homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__image {
    margin-bottom: 0px;
  }
  .homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__image img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }
  .homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__quote {
    text-align: right;
    color: var(--primarys);
    font-size: 20px;
  }
  .homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__name__org a {
    font-size: 20px;
    color: black;
    margin-right: 10px;
    font-weight: 600 !important;
  }
  .homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__name__org label {
    font-size: 20px;
    opacity: 0.4;
  }
  .homepage__review .reviewcarousel .reviewcarousel__item .reviewcarousel__review {
    margin-top: 10px;
    font-size: 18px;
    opacity: 0.5;
    letter-spacing: -0.01px;
  }
}

.forget__form {
  margin-top: 200px;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}

.forget__form p:nth-child(1) {
  font-size: 20px;
  opacity: 0.8;
  margin-top: 20px;
}

.forget__form p:nth-child(4) {
  font-size: 20px;
  opacity: 0.8;
  margin-top: 20px;
}

.forget__form p:nth-child(4) input {
  margin-right: 10px;
}

.forget__form p:nth-child(6) {
  font-size: 17px;
  opacity: 0.8;
  margin-top: 20px;
}

.forget__form p:nth-child(6) input {
  margin-right: 10px;
}

.forget__form input[type='text'],
.forget__form input[type='password'],
.forget__form input[type='email'] {
  border: 1px solid #eee;
  padding: 5px 10px;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  width: 100%;
  height: 45px;
  font-size: 18px;
}

.forget__form input[type='text']::-webkit-input-placeholder,
.forget__form input[type='password']::-webkit-input-placeholder,
.forget__form input[type='email']::-webkit-input-placeholder {
  color: black;
  opacity: 0.3;
}

.forget__form input[type='text']:-ms-input-placeholder,
.forget__form input[type='password']:-ms-input-placeholder,
.forget__form input[type='email']:-ms-input-placeholder {
  color: black;
  opacity: 0.3;
}

.forget__form input[type='text']::-ms-input-placeholder,
.forget__form input[type='password']::-ms-input-placeholder,
.forget__form input[type='email']::-ms-input-placeholder {
  color: black;
  opacity: 0.3;
}

.forget__form input[type='text']::placeholder,
.forget__form input[type='password']::placeholder,
.forget__form input[type='email']::placeholder {
  color: black;
  opacity: 0.3;
}

.forget__form .forgetbutton {
  width: 100%;
  border: 0px;
  margin-top: 10px;
  outline: 0px;
  padding: 10px 15px;
  color: white;
  font-size: 18px;
  height: 48px;
  text-transform: uppercase;
  font-weight: bold;
  background: #ff5a5f -webkit-gradient(linear, left top, left bottom, from(#ff7377), to(#ff5a5f)) repeat-x;
  background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
}

@media (max-width: 1000px) {
  .forget__form {
    margin-top: 150px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

button {
  outline: none;
  cursor: pointer;
}
/*# sourceMappingURL=main.css.map */
